import { makeAutoObservable } from "mobx"
import axios from "axios"
import sender from '../template/controllers/sender';
import store from "store"
import instance from '../../store/axios';
import loaderController from '../template/loader/loader.controller';
import hashController from '../template/controllers/hash.controller';

class ClientStatusesController {

    list = []

    item = {
        id: 0,
        name: '',
        countsale: 0,
        filepng: '',
        filesvg: ''
    }

    filesvg = null
    filepng = null

    pagination = {
        limit: 10,
        offset: 0,
    }

    constructor() {
        makeAutoObservable(this)
    }

    getList() {
        sender.get(`/user/userstatus?limit=${this.pagination.limit}&offset=${this.pagination.offset}`)
            .then(response => {
                console.log(response)
                this.list = response.data
            })
    }

    setPage(page) {
        this.pagination.offset = page > 1 ? (page * this.pagination.limit) - this.pagination.limit : 0
    }

    getOne(id) {
        if(id > 0)
            sender.get(`/user/userstatus/${id}`)
                .then(response => {
                    console.log(response)
                    this.item = response.data
                })
    }

    destroy(id) {
        if(window.confirm('Вы действительно хотите удалить элемент?')) {
            const data = new FormData()
            data.append('test', 'test')
            data.append("hash", hashController.setHash(data))

            sender.post(`/user/userstatusdel/${id}`, data)
                .then(response => {
                    console.log(response)
                    this.getList()
                })
        }
    }

    save(e) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.item.name)
        data.append('countsale', this.item.countsale)

        if(this.item.filesvg === '')
            data.append('delete_filesvg', 1)

        if(this.item.filepng === '')
            data.append('delete_filepng', 1)

        data.append("hash", hashController.setHash(data))

        if(this.filesvg)
            data.append('filesvg', this.filesvg)
        else {

        }

        if(this.filepng)
            data.append('filepng', this.filepng)
        else {
        }

        sender.post(`/user/userstatus/${this.item.id}`, data)
            .then(response => {
                console.log(response)
                if(this.item.id > 0)
                    window.location.reload()
                else
                    window.location.href = `/client_statuses`
            })
    }

}

export default new ClientStatusesController()