import { observer } from "mobx-react-lite"
import controller from './client_statuses.controller'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import TemplateTitle from '../template/title/TemplateTitle'
import { useTranslation } from "react-i18next"
import {SettingOutlined, InboxOutlined, DeleteOutlined} from "@ant-design/icons"
import { Button, Table, Tooltip, Row, Col, Input, InputNumber, Upload, Space } from 'antd'

export const ClientStatuses = observer(() => {

    const { t } = useTranslation()

    useEffect(() => {
        controller.getList()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: "Статус",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('users:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title={t('users:view')}>
                        <Button type="dashed" shape="circle" href={"/client_statuses/" + record.id} icon={<SettingOutlined />} />
                    </Tooltip>
                    <Button type="dashed" shape="circle" danger icon={<DeleteOutlined />} onClick={() => controller.destroy(record.id)} />
                </Space>
            ),
        },
    ];

    return(
        <>
            <TemplateTitle
                title="Статусы пользователей"
                breadcrumb={[]}
                buttons={[
                    { type: "link", title: "Создать", link: '/client_statuses/0' }
                ]}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                pagination={false}
            />
        </>
    )
})

export const ClientStatus = observer(() => {

    let { id } = useParams()
    const { t } = useTranslation()

    useEffect(() => {
        controller.getOne(id)
    }, [])

    return(
        <form onSubmit={(e) => controller.save(e)}>
            <TemplateTitle
                title="Редактирование статуса"
                breadcrumb={[]}
                buttons={[
                    { type: "link", title: "Вернуться к списку", link: '/client_statuses' },
                    { type: "submit", title: "Сохранить", primary: true }
                ]}
            />

            <Row gutter={[20, 20]}>
                <Col span={12} className="gutter-row">
                    <label htmlFor="name" className="all-label">Наименование</label>
                    <Input
                        value={controller.item.name}
                        id="name"
                        onChange={(e) => controller.item.name = e.target.value}
                        required
                    />
                </Col>
                <Col span={12} className="gutter-row">
                    <label htmlFor="name" className="all-label">Кол-во продаж</label>
                    <InputNumber
                        value={controller.item.countsale}
                        onChange={(e) => controller.item.countsale = e}
                        style={{ width: '100%' }}
                        required
                    />
                </Col>
                <Col span={12}>
                    <label className="all-label">Файл SVG</label>

                    <Row gutter={[15, 15]}>
                        {controller.item.filesvg &&
                            <>
                                <Col span={24}>
                                    <img src={controller.item.filesvg} alt="" width="150px" />
                                </Col>
                                <Col span={24}>
                                    <Button onClick={() => controller.item.filesvg = ''}>Удалить файл</Button>
                                </Col>
                            </>
                        }
                        <Col span={24}>
                            <Upload
                                customRequest={e => {
                                    controller.filesvg = e.file
                                    console.log(e)
                                    return true
                                }}
                            >
                                <Button>Загрузить новый файл</Button>
                            </Upload>
                        </Col>
                    </Row>

                </Col>
                <Col span={12}>
                    <label className="all-label">Файл PNG</label>

                    <Row gutter={[15, 15]}>
                        {controller.item.filepng &&
                            <>
                                <Col span={24}>
                                    <img src={controller.item.filepng} alt="" width="150px" />
                                </Col>
                                <Col span={24}>
                                    <Button onClick={() => controller.item.filepng = ''}>Удалить файл</Button>
                                </Col>
                            </>
                        }
                        <Col span={24}>
                            <Upload
                                customRequest={e => {
                                    controller.filepng = e.file
                                    console.log(e)
                                    return true
                                }}
                            >
                                <Button>Загрузить новый файл</Button>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    )
})