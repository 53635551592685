import {Route, Switch} from "react-router-dom";

import ShopProduct from "./product/modules/product/ShopProduct";
import SubProduct from "../../pages/shop/catalog/product/Sub";
import Categories from "../../pages/shop/catalog/category/Categories";
import React from "react";
import ShopProductStatuses from "./product/modules/statuses/ShopProductStatuses";
import SearchProducts from "../../pages/shop/catalog/Search";
import OrderOrders from "./order/orders/OrderOrders";
import OrderStatuses from "./order/status/OrderStatuses";
import OrderOrder from "./order/order/OrderOrder";
import Points from "../../pages/shop/points/Points";
import Point from "../../pages/shop/points/Point";
import Delivery from "../../pages/shop/delivery/Delivery";
import Import from "../../pages/shop/import/Import";
// import ShopCategories from "./catalog/category/ShopCategories";
import ShopProductModeration from "./product/modules/product/ShopProductModeration";
import ShopProperties from "./properties/ShopProperties";
import ShopProperty from "./properties/modules/ShopProperty";
import ShopCategoryEdited from "./catalog/category/ShopCategoryEdited";
import {ClientStatus, ClientStatuses} from '../client_statuses/ClientStatuses';
import store from "store"

const moderator = store.get("user") && store.get("user").moderator

const shopRoutes = [
    { key: "product", path: "/shop/catalog/product/:productID", exact: true, component: <ShopProduct /> },
    { key: "productcreate", path: "/shop/catalog/product/create/:createID", exact: true, component: moderator ? <></> : <ShopProduct /> },
    { key: "productsub", path: "/shop/catalog/product/:productID/sub/:subID", exact: true, component: moderator ? <></> : <SubProduct /> },

    { key: "shop", path: "/shop", exact: true, component: moderator ? <></> : <Categories /> },
    { key: "catalog", path: "/shop/catalog", exact: true, component: moderator ? <></> : <Categories /> },
    { key: "category", path: "/shop/catalog/category", exact: true, component: moderator ? <></> : <Categories /> },
    { key: "categoryID", path: "/shop/catalog/category/:categoryID", exact: true, component: moderator ? <></> : <Categories /> },
    { key: "edit", path: "/shop/catalog/category/edit/:categoryID", exact: true, component: moderator ? <></> : <ShopCategoryEdited /> },
    { key: "create", path: "/shop/catalog/category/create/:createID", exact: true, component: moderator ? <></> : <ShopCategoryEdited /> },

    { key: "status", path: "/shop/status", exact: true, component: moderator ? <></> : <ShopProductStatuses /> },
    { key: "search", path: "/shop/search*", exact: true, component: moderator ? <></> : <SearchProducts /> },

    { key: "orders", path: "/shop/orders", exact: true, component: moderator ? <></> : <OrderOrders /> },
    { key: "statuses", path: "/shop/statuses", exact: true, component: moderator ? <></> : <OrderStatuses /> },
    { key: "orderID", path: "/shop/orders/:orderID", component: moderator ? <></> : <OrderOrder /> },

    { key: "points", path: "/shop/points", exact: true, component: moderator ? <></> : <Points /> },
    { key: "pointID", path: "/shop/points/:pointID", exact: true, component: moderator ? <></> : <Point /> },

    { key: "delivery", path: "/shop/delivery", exact: true, component: moderator ? <></> : <Delivery /> },
    { key: "import", path: "/shop/import", exact: true, component: moderator ? <></> : <Import /> },

    { key: "properties", path: "/shop/properties", exact: true, component: moderator ? <></> : <ShopProperties /> },
    { key: "propertyID", path: "/shop/properties/:propertyID", exact: true, component: moderator ? <></> : <ShopProperty /> },

    { key: "moderation", path: "/shop/moderation", exact: true, component: <ShopProductModeration /> },

    { key: "client_statuses", path: "/client_statuses", exact: true, component: moderator ? <></> : <ClientStatuses /> },
    { key: "client_statuses", path: "/client_statuses/:id", exact: true, component: moderator ? <></> : <ClientStatus /> },
]

const ShopRoutes = () => shopRoutes.map(item => (
        <>
            { item.exact
                ? <Route key={item.key} exact path={item.path} children={item.component} />
                : <Route key={item.key} path={item.path} children={item.component} />
            }
        </>
    ))

export default ShopRoutes