import { observer } from "mobx-react-lite"
import { Col, Radio, Table, Input, Button, Card, Space } from "antd"
import _elements from "../controllers/helpers/_elements.helper"
import ProductsListHelper from "../../../../helpers/products/ProductsListHelper";
import {useEffect} from 'react';

const SelectNewsOrCategory = observer(({ select, refs, inRoot = true }) => {
    const columnsNews = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <>
                    {record.id === Number(select)
                        ?   <Button type="primary">Выбрано</Button>
                        :   <Button onClick={() => refs(record.id)}>Выбрать</Button>
                    }
                </>
            )
        }
    ]

    const columnsCatalog = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (record) => (
                <Space>

                    {record.subcategory
                        ?   <>
                                {record.id === Number(select)
                                    ?   <Button type="primary">Выбрано</Button>
                                    :   <Button onClick={() => refs(record.id)}>Выбрать</Button>
                                }
                                {inRoot ? <Button onClick={() => _elements.get(record.id)}>Перейти</Button> : <></>}

                            </>
                        :   <>
                                {record.id === Number(select)
                                    ?   <Button type="primary">Выбрано</Button>
                                    :   <Button onClick={() => refs(record.id)}>Выбрать</Button>
                                }
                            </>
                    }


                    {/*{record.subcategory && <Button onClick={() => _elements.get(record.id)}>Перейти</Button>}*/}
                    {/*{!record.subcategory && !record.id_crm && <Button onClick={() => _elements.getProducts(record.id)}>Открыть</Button>}*/}

                    {/*{type === "category" && <Button onClick={() => refs(record.id)}>Выбрать</Button>}*/}
                    {/*{record.id_crm && <Button onClick={() => refs(record.id)}>Выбрать</Button>}*/}
                </Space>
            )
        },
    ]

    return(
        <>
            <Table
                columns={_elements.type === "news" ? columnsNews : columnsCatalog}
                dataSource={_elements.list}
                pagination={false}
            />
        </>
    )
})

const TypeLink = observer(({ type, link, refs, refType, refImage, refsMultiple, element }) => {

    useEffect(() => {
        if(type === 'products_list')
            _elements.change("products_list")
    }, [])

    const onChangeType = (value) => {
        refType(value)
        element = false
        _elements.change(value)
    }

    return(
        <>
            <Col span={24} className="gutter-row">
                <label className="all-label">Выберите тип ссылки</label>

                <Radio.Group value={type} onChange={(e) => onChangeType(e.target.value)}>
                    <Radio.Button value="news">Новость</Radio.Button>
                    <Radio.Button value="category">Категория товаров</Radio.Button>
                    <Radio.Button value="product">Товар</Radio.Button>
                    <Radio.Button value="products_list">Список товаров</Radio.Button>
                    <Radio.Button value="url">Сайт</Radio.Button>
                    <Radio.Button value="root_category">Новинки</Radio.Button>
                    <Radio.Button value="">Без перехода</Radio.Button>
                </Radio.Group>
            </Col>

            <Col span={24}>
                {type !== "" && <label className="all-label">Назначение ссылки</label>}

                {element &&
                    <>
                        {type === "news" &&
                            <>
                                <a href={`/news/${element.id}`} target="_blank">
                                    <Card
                                        hoverable
                                        style={{ width: 240 }}
                                        cover={element.image && element.image.path && element.image.path !== "" && <img alt={element.name} src={element.image.path} />}
                                    >
                                        <Card.Meta title={element.name} description={`Новость ID = ${element.id}`} />
                                    </Card>
                                </a>
                                <br/>
                                <Button onClick={() => _elements.change("news")}>Выбрать другую новость</Button>
                            </>
                        }
                        {type === "category" &&
                            <>
                                <a href={`/shop/catalog/category/edit/${element.id}`} target="_blank">
                                    <Card
                                        hoverable
                                        style={{ width: 240 }}
                                        cover={element.image && element.image.path && element.image.path !== "" && <img alt={element.name} src={element.image.path} />}
                                    >
                                        <Card.Meta title={element.name} description={`Категория ID = ${element.id}`} />
                                    </Card>
                                </a>
                                <br/>
                                <Button onClick={() => _elements.change("category")}>Выбрать другую категорию</Button>
                            </>
                        }
                        {type === "root_category" &&
                            <>
                                <a href={`/shop/catalog/category/edit/${element.id}`} target="_blank">
                                    <Card
                                        hoverable
                                        style={{ width: 240 }}
                                        cover={element.image && element.image.path && element.image.path !== "" && <img alt={element.name} src={element.image.path} />}
                                    >
                                        <Card.Meta title={element.name} description={`Категория ID = ${element.id}`} />
                                    </Card>
                                </a>
                                <br/>
                                <Button onClick={() => _elements.change("category")}>Выбрать другую категорию</Button>
                            </>
                        }
                        {type === "product" &&
                        <>
                            <a href={`/shop/catalog/product/${element.id}`} target="_blank">
                                <Card
                                    hoverable
                                    style={{ width: 240 }}
                                    cover={element.image && element.image.path && element.image.path !== "" && <img alt={element.name} src={element.image.path} />}
                                >
                                    <Card.Meta title={element.name} description={`Товар ID = ${element.id}`} />
                                </Card>
                            </a>
                            <br/>
                            <Button onClick={() => _elements.change("product")}>Выбрать другой товар</Button>
                        </>
                        }
                    </>
                }

                {type !== "news" && type !== "category" && type !== "product" && type !== "products_list" && type !== "" &&
                    <>
                        <Input
                            value={link}
                            onChange={(e) => refs(e.target.value)}
                            required
                        />
                    </>
                }

                {_elements.modal && (type === "news" || type === "category" || type === "root_category") &&
                    <>
                        <label className="all-label">Выберите другое назначение ссылки</label>
                        <SelectNewsOrCategory select={link} refs={refs} inRoot={type !== "root_category"} />
                    </>
                }

                {_elements.modal && type === "product" &&
                    <ProductsListHelper actions={[ { title: "Выбрать", refs: (e) => refs(e), refImage: (e) => refImage(e) } ]} selected={[link]} />
                }

                {_elements.modal && type === "products_list" &&
                    <ProductsListHelper
                        actions={[ { title: "Выбрать", type: true, refs: (e) => refs(e), refImage: (e) => refImage(e), refsMultiple: (e) => refsMultiple(e) } ]}
                        selected={Array.isArray(link) ? link : []}
                        multiple={true}
                    />
                }
            </Col>
        </>
    )
})

export default TypeLink