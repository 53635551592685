import {observer} from "mobx-react-lite";
import TemplateTitle from "../../../../template/title/TemplateTitle";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import productController from "./controllers/productController"
import {Button, Image, Pagination, Space, Switch, Table, Tooltip} from "antd";
import {CopyOutlined, CloseOutlined, CheckOutlined, SettingOutlined, UpOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import dayjs from 'dayjs';

const ShopProductModeration = observer(() => {

    const { t } = useTranslation();

    const breadcrumb = [
        { link: "/shop", value: t('orders:shop') },
        { link: null, value: "Модерация товаров" }
    ]

    const columnsProducts = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('categories:idCrm'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: t('categories:picture'),
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text !== "[]" && text !== "Array") {
                    text = JSON.parse(text);
                    return(
                        <>
                            <Image
                                width={'100px'}
                                src={text[0].path}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('categories:price'),
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: t('categories:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: 'Когда поступило на модерацию',
            dataIndex: 'datecreate',
            key: 'datecreate',
            render: (e) => dayjs(e*1000).format('DD.MM.YYYY HH:mm')
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title="Одобрить">
                            <Button type="dashed" shape="circle" icon={<CheckOutlined />} onClick={() => productController.setModerationProductByProductID(record.id, 1)} />
                        </Tooltip>
                        <Tooltip title="Отклонить">
                            <Button type="dashed" shape="circle" icon={<CloseOutlined />} onClick={() => productController.setModerationProductByProductID(record.id, 2)} />
                        </Tooltip>
                        <Tooltip title={t('categories:edit')}>
                            <Link to={"/shop/catalog/product/" + record.id}>
                                <Button type="dashed" shape="circle" icon={<SettingOutlined />} />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Пользователь">
                            <Link to={`/users/${record.userid}`} target="_blank">
                                <Button type="dashed" shape="circle" icon={<UserOutlined />} />
                            </Link>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    useEffect(() => {
        productController.getProductsToModeration()
    }, [])


    return(
        <>
            <TemplateTitle
                title="Товары на модерации"
                breadcrumb={breadcrumb}
            />

            <Table columns={columnsProducts} dataSource={productController.products} pagination={false} />

            {productController.pagination !== null &&
            <>
                <br/>
                <Pagination
                    defaultCurrent={productController.pagination.page}
                    total={Number(productController.pagination.all)}
                    showSizeChanger={false}
                    onChange={e => productController.setPageModeration(e)}
                />
            </>
            }
        </>
    )
})

export default ShopProductModeration