import { observer } from "mobx-react-lite"
import { Row, Col, Checkbox, Input, Radio, Table, Space, Button, Tooltip } from "antd"
import _main from "../../controllers/_main.screen.controller"
import ProductsListHelper from "../../../../../helpers/products/ProductsListHelper";
import MainModuleStatuses from "../MainModuleStatuses";
import _elements from "../../controllers/helpers/_elements.helper";
import MainModuleCategories from "../MainModuleCategories";

const MainModuleProductModal = observer(() => {

    const columnsProducts = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text && text[0] && text[0].path && text[0].path !== "") {
                    return <img src={text[0].path} alt="" width={`80px`} />
                } else {
                    return ""
                }
            }
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Действия',
            key: 'actions',
            render: record => (
                <Space>
                    <Button danger onClick={() => _main.deleteProductFromArray(record.id, "ksblockproduct", record)}>Убрать из списка</Button>
                </Space>
            )
        }
    ]

    let el = "ksblockproduct"

    return _main.selected[el] ? (
        <Row gutter={16}>
            <Col span={24}>
                <Checkbox
                    checked={_main.selected[el] && Number(_main.selected[el].active) > 0}
                    onChange={(e) => _main.set("active", el, e.target.checked ? 1 : 0)}
                >
                    Активность
                </Checkbox>
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="name" className="all-label">Наименование</label>
                <Input
                    value={_main.selected[el].name}
                    id="name"
                    onChange={(e) => _main.set("name", el, e.target.value)}
                />
            </Col>
            <Col span={24}>
                <label className="all-label">Выберите тип вывода товаров</label>

                <Radio.Group value={_main.selected[el].type} onChange={(e) => _main.changeProduct(e.target.value, "ksblockproduct")}>
                    <Radio.Button value="productcategory">Категория товаров</Radio.Button>
                    <Radio.Button value="product">Список товаров</Radio.Button>
                    <Radio.Button value="status">Товары по статусу</Radio.Button>
                </Radio.Group>
            </Col>
            <Col span={24}>
                {_main.selected[el].type === "product" && Array.isArray(_main.selected[el].blockid) && _main.selected[el].blockid.length >= 0 &&
                    <>
                        <Table columns={columnsProducts} dataSource={_main.selected[el].product} pagination={false} />

                        <label className="all-label">Добавить товары к списку</label>

                        <ProductsListHelper
                            actions={[ { title: "Выбрать", type: "true", refs: (e) => _main.addProductToArray(e, "ksblockproduct") } ]}
                            selected={_main.selected[el].blockid ? _main.selected[el].blockid : []}
                        />
                    </>
                }
                {_main.selected[el].type === "productcategory" &&
                    <>
                        <label className="all-label">Изменить категорию</label>
                        <MainModuleCategories select={_main.selected[el].blockid } />
                    </>
                }
                {_main.selected[el].type === "status" &&
                    <>
                        <MainModuleStatuses
                            select={_main.selected[el].blockid}
                            refs={(e) => _main.set("blockid", "ksblockproduct", e)}
                        />
                        <label className="all-label">Изменить статус</label>
                    </>
                }
            </Col>

        </Row>
    ) : <></>
})

export default MainModuleProductModal