import React, { useState, useEffect } from 'react';
import {notification, Col, Descriptions, Row, Typography, Button, Breadcrumb, Select} from 'antd';
import {withTranslation, useTranslation} from "react-i18next";
import instance from "../../store/axios";
import {useParams} from "react-router-dom";
import Client_statusesController from '../../modules/client_statuses/client_statuses.controller';
import { observer } from 'mobx-react-lite'
import hashController from '../../modules/template/controllers/hash.controller';
import sender from '../../modules/template/controllers/sender';

const { Title } = Typography;

export const UserDetail = observer(() => {
    let { userID } = useParams();
    const { t } = useTranslation();
    const [user, setUser] = useState({
        id: 0,
        firstname: '',
        lastname: '',
        surname: '',
        email: '',
        phone: '',
        birthday: 0,
        userstatusid: 0
    });

    useEffect(() => {
        instance.get('/users/user/' + userID + '/').then(response => {
            console.log(response);

            let date = 0;
            if(Number(response.data.data.birthday) > 0) {
                date = new Date(Number(response.data.data.birthday) * 1000);

                date = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
            }

            setUser({
                id: response.data.data.id,
                firstname: response.data.data.firstname,
                lastname: response.data.data.lastname,
                surname: response.data.data.surname,
                email: response.data.data.email,
                phone: response.data.data.phone,
                userstatusid: response.data.data.userstatusid,
                birthday: date,
            })
        })
    }, []);

    const save = (userstatusid) => {

        const data = new URLSearchParams()
        data.append('userstatusid', userstatusid)

        sender.put('/user/user/' + userID + '/', data.toString(), {
            headers: {
                "Content-Type" : "application/x-www-form-urlencoded"
            }
        })
            .then(result => {
                console.log(result)
                notification.success({
                    placement: "bottomLeft",
                    message: t('categories:success'),
                    description: "Данные успешно изменены",
                });
            })
            .catch(e => {
                notification.error({
                    placement: "bottomLeft",
                    message: "Ошибка",
                    description: "Ошибка изменения данных",
                });
            })
    }

    useEffect(() => {
        Client_statusesController.pagination.limit = 1000
        Client_statusesController.getList()
    }, [])

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('user:info')}</Title>
                </Col>
                <Col span={8} className="right">
                    <Button type="dashed" href={"/users"}>{t('user:list')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('user:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/users"}>{t('user:users')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('user:user')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Descriptions
                        size="small"
                    >
                        <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
                        <Descriptions.Item label="E-mail">{user.email}</Descriptions.Item>
                        <Descriptions.Item label={t('user:phone')}>{user.phone}</Descriptions.Item>
                        <Descriptions.Item label={t('user:name')}>{user.firstname}</Descriptions.Item>
                        <Descriptions.Item label={t('user:surname')}>{user.lastname}</Descriptions.Item>
                        <Descriptions.Item label={t('user:middle')}>{user.surname}</Descriptions.Item>
                        {Number(user.birthday) !== 0 &&
                            <Descriptions.Item label={t('user:birthday')}>{user.birthday}</Descriptions.Item>
                        }
                    </Descriptions>
                </Col>
                <Col span={6}>
                    <label>Статус пользователя</label>
                    <Select
                        options={[{ label: '', value: 0 },
                            ...Client_statusesController.list.map((el) => ({
                                label: el.name,
                                value: Number(el.id)
                            }))]}
                        style={{ width: '100%' }}
                        value={Number(user.userstatusid)}
                        onChange={(e) => {
                            setUser({...user, userstatusid: e})
                            save(e)
                        }}
                    />
                </Col>
            </Row>
        </>
    )
})